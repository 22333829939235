import { graphql, Link } from "gatsby";
import { CategoryTemplateQuery } from "../../graphql-schema-types";
import { Heading3 } from "../components/Atoms/Text";
import React, { Fragment } from "react";
import Seo from "../components/Seo";
import { css } from "@emotion/core";
import { verticalMargin } from "../margins";
import { bpLarge, bpMedium } from "../stylingConstants";
import { getYearRoute } from "../routeHelper";
import {
  MainCategoryHeading,
} from "../components/Atoms/CategoryHeading";
import { EntryPresentation } from "../components/Organisms/SharedAwardPresentation";
import leftArrow from "../assets/leftArrow.svg";
import { extractAwardData } from "../formatModels";

type Props = {
  data: CategoryTemplateQuery;
  pageContext: { categoryName: string };
};

export default function Category({
  data: {
    allContentfulAward: { nodes: awards },
  },
  pageContext: { categoryName: name },
}: Props) {
  const CategoryHeading = Heading3.withComponent("h1");
  return (
    <>
      <Seo title={name} />
      <CategoryHeading>{name}</CategoryHeading>
      <hr
        css={css`
          border-top: 1px solid #dedede;
          border-bottom: none;
          ${verticalMargin(25)}
          @media ${bpLarge}{
            ${verticalMargin(35)}
          }
        `}
      />
      {awards?.map((source) => {
        const year: number = source?.year?.[0]?.year;
        if (year == null || source == null) {
          return null;
        }
        const award = extractAwardData(source);
        return (
          <Fragment key={year}>
            <Link to={getYearRoute(year)}>
              <MainCategoryHeading
                css={css`
                  ::after {
                    content: url(${leftArrow});
                    display: inline-block;
                    margin-left: 20px;
                    position: relative;
                    top: 0px;
                    transform: rotate(180deg);
                    margin-left: 10px;
                    @media ${bpMedium} {
                      transform: scale(1.2) rotate(180deg);
                      margin-left: 15px;
                      top: 2px;
                    }
                    @media ${bpLarge} {
                      transform: scale(1.5) rotate(180deg);
                      margin-left: 20px;
                      top: 11px;
                    }
                  }
                `}
              >
                {year}
              </MainCategoryHeading>
            </Link>
            {award.winner && (
              <EntryPresentation type="winner" data={award.winner} />
            )}
            {award.nominees?.map((nominee) => (
              <EntryPresentation
                type="nominated"
                data={nominee}
                key={nominee.title}
              />
            ))}
          </Fragment>
        );
      })}
    </>
  );
}

export const query = graphql`
  query CategoryTemplate($awardIds: [String]) {
    allContentfulAward(
      filter: { id: { in: $awardIds } }
      sort: { fields: [year___year], order: DESC }
    ) {
      nodes {
        year {
          year
        }
        ...AwardSelection
      }
    }
  }
`;
